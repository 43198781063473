import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from './styles';

import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength: number;
  isDisabled?: boolean;
  control?: Control<FieldValues, any>;
}

export const InputPassword: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  maxLength,
  isDisabled = false,
  control,
  ...rest
}) => {
  const [openEyes, setOpenEyes] = useState<boolean>(false);

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {control ? (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <div className="input-password">
                      <input
                        {...register(name)}
                        maxLength={maxLength}
                        type={openEyes ? 'text' : 'password'}
                        disabled={isDisabled}
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        value={value || ''}
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          onChange(event.target.value);
                        }}
                        {...rest}
                      />

                      <div
                        className="icon"
                        onMouseDown={() => setOpenEyes(true)}
                        onMouseUp={() => setOpenEyes(false)}
                      >
                        {!openEyes ? (
                          <FaRegEyeSlash size={16} color="#999999" />
                        ) : (
                          <FaRegEye size={16} color="#999999" />
                        )}
                      </div>
                    </div>
                  </>
                );
              }}
            />
          ) : (
            <div className="input-password">
              <input
                {...register(name)}
                maxLength={maxLength}
                disabled={isDisabled}
                type={openEyes ? 'text' : 'password'}
                className={isError ? 'form-control is-invalid' : 'form-control'}
                placeholder={placeholder}
                {...rest}
              />

              <div
                className="icon"
                onMouseDown={() => setOpenEyes(true)}
                onMouseUp={() => setOpenEyes(false)}
              >
                {!openEyes ? (
                  <FaRegEyeSlash size={16} color="#999999" />
                ) : (
                  <FaRegEye size={16} color="#999999" />
                )}
              </div>
            </div>
          )}
        </Form.Group>
      </InputContainer>
    </>
  );
};
