import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { FaPercent } from 'react-icons/fa';
import { formatCurrencyAsText } from '~/utils/functions';
import { InputContainer } from '../styles';
import { pesoFormatado } from './formatMask';
import { IconContainer, InputIcon } from './styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength?: number;
  max: number;
  min: number;
  decimals?: number;
  step?: string;
  showIcon?: boolean;
  isDisabled?: boolean;
  qtdDecimais?: number;
  control: Control<FieldValues, any>;
}

export const InputPercent: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  max = 100,
  name,
  register,
  isError,
  showIcon = true,
  step = '1',
  isDisabled = false,
  maxLength = 6,
  qtdDecimais = 2,
  control,
  ...rest
}) => {
  const [prevValue, setPrevValue] = useState('');

  const validaPercent = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return;
    }

    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return;
    }

    if (value.includes('-')) {
      event.target.value = prevValue;
      return;
    }

    const serializedValue = pesoFormatado(value, qtdDecimais);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }

    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return;
    }
    setPrevValue(serializedValue);
    event.target.value = serializedValue;
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <InputIcon showIcon={showIcon}>
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <div style={{ position: 'relative' }}>
                      <input
                        {...register(name)}
                        disabled={isDisabled}
                        maxLength={maxLength}
                        type="text"
                        step={step}
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          validaPercent(event);
                          onChange(event.target.value);
                        }}
                        {...rest}
                      />
                      {showIcon && (
                        <IconContainer className="container-icon">
                          <FaPercent className="input-icon" />
                        </IconContainer>
                      )}
                    </div>
                  </>
                );
              }}
            />
          </InputIcon>
        </Form.Group>
      </InputContainer>
    </>
  );
};
