import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import { pesoFormatado } from './formatMask';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  max?: number;
  min?: number;
  decimals: 1 | 2 | 3;
  control: Control<FieldValues, any>;
}

const getPlaceholder = (decimals: number) => {
  switch (decimals) {
    case 2: {
      return '0,00';
    }
    case 3: {
      return '0,000';
    }
    default:
      return '0,0';
  }
};

export const InputPeso: React.FC<InputTextProps> = ({
  label = '',
  decimals = 2,
  max = 999999999,
  placeholder = getPlaceholder(decimals),
  name,
  register,
  isError,
  control,
  ...rest
}) => {
  const [prevValue, setPrevValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return;
    }

    const regexNumber = new RegExp(/[\d]$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return;
    }

    const serializedValue = pesoFormatado(value, decimals);
    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }

    if (parseFloat(valueToFloat) > max) {
      event.target.value = prevValue;
      return;
    }

    setPrevValue(serializedValue);
    event.target.value = serializedValue;
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <div style={{ position: 'relative' }}>
                    <input
                      {...register(name)}
                      type="text"
                      style={{
                        textAlign: 'right',
                      }}
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                        onChange(event.target.value);
                      }}
                      maxLength={decimals === 2 ? 14 : 15}
                      {...rest}
                    />
                  </div>
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
