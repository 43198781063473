import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';
import Button from '@atlaskit/button';
import { AiOutlinePlus } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength: number;
  max: number;
  min?: number;
  showIcon?: boolean;
  control: Control<FieldValues, any>;
  hasButton?: boolean;
  loadingIcon?: boolean;
  clickedButton?: () => void;
}

export const InputNumber2: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  maxLength,
  max = 99999999,
  min = -99999999,
  control,
  hasButton = false,
  loadingIcon = false,
  clickedButton,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <div style={{ position: 'relative' }}>
                  <input
                    {...register(name)}
                    type="number"
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    value={value}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.value);
                    }}
                    max={max}
                    min={min}
                    maxLength={maxLength}
                    {...rest}
                  />
                  {hasButton && (
                    <Button
                      type="button"
                      className="button-input"
                      onClick={() => {
                        if (clickedButton) {
                          clickedButton();
                        }
                      }}
                    >
                      {loadingIcon ? (
                        <CircularProgress style={{ color: '#fff' }} size={15} />
                      ) : (
                        <AiOutlinePlus color="#fff" />
                      )}
                    </Button>
                  )}
                </div>
                // <input
                //   {...register(name)}
                //   type="text"
                //   maxLength={maxLength}
                //   className={
                //     isError ? 'form-control is-invalid' : 'form-control'
                //   }
                //   value={value}
                //   placeholder={placeholder}
                //   onChange={(event: ChangeEvent<HTMLInputElement>) => {
                //     onChange(event.target.value.toUpperCase());
                //   }}
                //   {...rest}
                // />
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
