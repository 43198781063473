import styled from 'styled-components';

interface InputIconProps {
  showIcon: boolean;
}

export const InputIcon = styled.div<InputIconProps>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: ${(props) => (props.showIcon ? '44px !important' : 'none')};
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: 2px;
  right: 0;
  margin-right: 2px;
  height: calc(100% - 4px);
  width: 40px;
  background-color: #e7e9ed;
  border-radius: 3px;
  .input-icon {
    font-size: 14px;
    color: #42526e;
  }
`;
