/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Lista } from './Lista';
import { InputSelectProps } from './protocols';
import { InputContainer, InputTagSt } from './styles';
import TagsInput from 'react-tagsinput';

export const InputMultiSelect: React.FC<InputSelectProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  options,
  isDisabled = false,
  ...rest
}) => {
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any[]>([]);
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');

  function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
    event.target.value = event.target.value.toUpperCase();
    setFilter(event.target.value.toUpperCase());
  }
  const handleTags = (selectTags: string[]) => {
    const Options = selectTags.map((option) => {
      return JSON.parse(option);
    });
    setSelected(Options);
    changeSelected(Options);
  };

  function onClickInput(event: any) {
    event.target.value = '';
    setIsOpen(true);
  }

  // useEffect(() => {
  //   (async () => {
  //     const contents = await document.getElementsByClassName(
  //       'react-tagsinput-tag',
  //     );
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (let i = 0; i < contents.length; i++) {
  //       let text = String(contents[i]?.textContent).replace('{"label":"', '');
  //       const position = text.indexOf('value');
  //       if (position - 1) {
  //         text = text.substring(0, position - 3);
  //         const paragrafo = document.createElement(`p`);
  //         paragrafo.textContent = text;
  //         contents[i].appendChild(paragrafo);
  //       }
  //     }
  //   })();
  // }, []);

  return (
    <>
      <InputContainer>
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}

          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value } }) => {
              return (
                <>
                  <label className="select-container">
                    <InputTagSt>
                      <TagsInput
                        value={selected.map((option) => {
                          return JSON.stringify(option);
                        })}
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        onChange={(e) => {
                          handleTags(e);
                        }}
                        inputProps={{
                          placeholder,
                          autoComplete: 'off',
                          ref: inputRef,
                          id: 'multiSelect',
                          type: 'text',
                          onChange: onChangeInput,
                          onClick: onClickInput,
                          ...rest,
                        }}
                      />
                    </InputTagSt>
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          role="presentation"
                        >
                          <path
                            d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                            fill="currentColor"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </label>
                  {isOpen && (
                    <Lista
                      options={options}
                      inputRef={inputRef}
                      setIsOpen={setIsOpen}
                      filter={filter}
                      setFilter={setFilter}
                      selected={selected}
                      setSelected={setSelected}
                      changeSelected={changeSelected}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
