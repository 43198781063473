import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength: number;
  max: number;
  min?: number;
  step?: string;
  showIcon?: boolean;
  control?: Control<FieldValues, any>;
  allowNegativeValue?: boolean;
  allowsComma?: boolean;
  disableWheel?: boolean;
}

export const InputNumber: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  maxLength,
  step = '1',
  max = 99999999,
  min = -99999999,
  control,
  allowNegativeValue = true,
  allowsComma = true,
  disableWheel = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key.toLowerCase() === 'e' ||
      (!allowsComma && event.key === ',')
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!disableWheel) return;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel, { passive: false });

      return () => inputElement.removeEventListener('wheel', handleWheel);
    }
  }, [disableWheel, allowNegativeValue]);

  let classNames = 'form-control';

  if (isError) classNames += ' is-invalid';

  if (disableWheel) classNames += ' no-spinners';

  return (
    <InputContainer>
      <Form.Group>
        {label !== '' && <Form.Label>{label}</Form.Label>}
        {control ? (
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              const val = value as string;

              if ((!val || val.length <= 0) && inputRef.current) {
                inputRef.current.value = '';
              }

              let hasMaxLength = false;

              if (val && val.length > maxLength && inputRef.current) {
                hasMaxLength = true;
                inputRef.current.value = String(val).slice(0, maxLength);
              }

              return (
                <input
                  {...register(name)}
                  type="number"
                  step={step}
                  className={classNames}
                  placeholder={placeholder}
                  max={max}
                  min={min}
                  maxLength={maxLength}
                  value={hasMaxLength ? null : value || null}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (!allowNegativeValue) {
                      const newValue = Math.max(
                        0,
                        parseFloat(event.target.value),
                      );
                      onChange(newValue);
                    } else {
                      onChange(event.target.value);
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                  {...rest}
                />
              );
            }}
          />
        ) : (
          <input
            {...register(name)}
            type="number"
            step={step}
            className={isError ? 'form-control is-invalid' : 'form-control'}
            placeholder={placeholder}
            max={max}
            min={min}
            maxLength={maxLength}
            onKeyDown={handleKeyDown}
            {...rest}
          />
        )}
      </Form.Group>
    </InputContainer>
  );
};
