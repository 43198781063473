import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputRadioProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  colorLabel?: string;
}

export const InputRadio: React.FC<InputRadioProps> = ({
  label = '',
  name,
  register,
  isError,
  colorLabel,
  ...rest
}) => {
  return (
    <InputContainer>
      <div className="spacing">
        <input id={label} {...register(name)} type="radio" {...rest} />
        <Form.Label className={colorLabel || ' '} htmlFor={label}>
          {label}
        </Form.Label>
      </div>
    </InputContainer>
  );
};
