import styled from 'styled-components';

export const InputContainer = styled.div`
  .form-control {
    font-size: 14px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    font-size: 14px;
    color: #a5adba;
    background-color: transparent;
    border: 0;
  }

  .form-group {
    margin-top: 0.5rem;
  }

  .form-control.is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
    padding: 0.375rem 0.75rem;
  }

  .form-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-weight: 600;
    line-height: 1.3333333333333333;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin-bottom: 4px;
  }
  .spacing {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem;
  }
  input {
    position: relative;
    width: 100%;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    font-size: 0.875rem;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &:focus {
      border-color: var(--ds-border-focus, #4c9aff);
      outline: none;
      box-shadow: none;
    }
  }
  .input-password {
    position: relative;
  }
  .icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  input[type='number'],
  input[type='weight'] {
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }
`;
